.video-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .video-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  
  video {
    width: 100%;
    max-height: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .download-instruction {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .arrow {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    color: white;
  }
  
  .download-options {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .download-button:hover {
    background-color: #45a049;
  }
  
  .download-tip {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  .additional-info {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .additional-info h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .additional-info ul {
    padding-left: 20px;
  }
  
  .additional-info li {
    margin-bottom: 10px;
    color: #555;
  }
  
  .loading, .error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
  
  .error {
    color: red;
  }