@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
}

.landing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero {
  text-align: center;
  padding: 60px 0;
  background-color: #f0f4f8;
  border-radius: 10px;
  margin-bottom: 40px;
}

.hero h1 {
  font-size: 2.5em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  color: #34495e;
}

.features {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.feature-text {
  flex: 1;
  padding-right: 40px;
}

.feature-text h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.feature-text ul {
  list-style-type: none;
  padding: 0;
}

.feature-text li {
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.feature-text li::before {
  content: '✓';
  color: #27ae60;
  position: absolute;
  left: 0;
}

.feature-video {
  flex: 1;
}

.feature-video video {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing {
  text-align: center;
  margin-bottom: 60px;
}

.pricing h2 {
  color: #2c3e50;
  margin-bottom: 30px;
}

.price-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 30px;
  max-width: 300px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.price-card h3 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.price {
  font-size: 2.5em;
  color: #27ae60;
  margin-bottom: 20px;
}

.price span {
  font-size: 0.5em;
  color: #7f8c8d;
}

.price-card ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.price-card li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.price-card li::before {
  content: '✓';
  color: #27ae60;
  position: absolute;
  left: 0;
}

.cta {
  background-color: #ecf0f1;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 60px;
}

.cta h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.cta form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.cta input,
.cta button {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-size: 1em;
}

.cta button {
  background-color: #27ae60;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta button:hover {
  background-color: #2ecc71;
}

.benefits {
  text-align: center;
  margin-bottom: 60px;
}

.benefits h2 {
  color: #2c3e50;
  margin-bottom: 40px;
}

.benefit-cards {
  display: flex;
  justify-content: space-between;
}

.benefit-card {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  margin: 0 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.benefit-card h3 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.final-cta {
  background-color: #34495e;
  color: white;
  text-align: center;
  padding: 60px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.final-cta h2 {
  margin-bottom: 20px;
}

.final-cta button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.final-cta button:hover {
  background-color: #c0392b;
}

footer {
  text-align: center;
  color: #7f8c8d;
  padding: 20px 0;
}

.status {
  color: #27ae60;
  margin-top: 10px;
}

.error {
  color: #e74c3c;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
  }
  
  .feature-text {
    padding-right: 0;
    margin-bottom: 20px;
  }
  
  .benefit-cards {
    flex-direction: column;
  }
  
  .benefit-card {
    margin: 10px 0;
  }
  
  .price-card {
    max-width: 100%;
  }
}