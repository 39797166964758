.admin-dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .admin-dashboard h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .admin-dashboard table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .admin-dashboard th, .admin-dashboard td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .admin-dashboard th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .admin-dashboard tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .admin-dashboard .status-ready {
    color: #28a745;
  }
  
  .admin-dashboard .status-processing {
    color: #ffc107;
  }
  
  .admin-dashboard .status-long-processing {
    color: #fd7e14;
  }
  
  .admin-dashboard .status-no-imagery {
    color: #6c757d;
  }
  
  .admin-dashboard .status-error {
    color: #dc3545;
  }
  
  .admin-dashboard a {
    color: #007bff;
    text-decoration: none;
  }
  
  .admin-dashboard a:hover {
    text-decoration: underline;
  }
  
  .loading, .error {
    padding: 20px;
    text-align: center;
    font-size: 18px;
  }
  
  .error {
    color: #dc3545;
  }